<template>
  <div class="wrap loginFlex">
    <Agreement/>
    <van-form ref="ruleForm">
      <van-field
        readonly
        v-model="cardType"
        name="cardType"
        label="证件类型"
      />
      <van-field
        v-model="cardId"
        ref="cardId"
        name="cardId"
        label="证件号码"
        maxlength="18"
        placeholder="请输入证件号码"
        :rules="[{ required: true},{validator:validatorCard,message: '请输入正确的证件号'}]"
      />
      <van-field
        v-model="telNum"
        ref="telNum"
        name="telNum"
        label="手机号码"
        maxlength="11"
        type="tel"
        :formatter="formatter"
        placeholder="请输入手机号码"
        :rules="[{ required: true},{ validator, message: '请输入11位手机号' }]"
      />
      <van-field
        v-model="smsCode"
        name="smsCode"
        maxlength="4"
        :formatter="formatter"
        center
        clearable
        type="number"
        label="验证码"
        placeholder="请输入验证码"
        :rules="[{ required: true}]"
      >
        <template #button>
          <van-button v-show="!visible" native-type="button" size="small" type="danger" @click="getSmsCode()">获取验证码</van-button>
          <van-button v-show="visible" native-type="button" class="block" plain size="small" type="primary">{{timeSeconds}}</van-button>
         

         <!-- <van-count-down
            ref="countDown"
            v-show="visible"
            :time="time"
            :auto-start="false"
            format="ss"
            @finish="finish"
          >
          <template #default="timeData">
            <span class="block">{{ timeData.seconds }}</span>
          </template>
        </van-count-down> -->
        </template>
      </van-field>
      
      <div class="tips">
        <p>温馨提示： </p>
        <p>1、每天获取验证码不能超过3次 </p>
        <p>2、我司工作人员不会以任何理由要求客户提供短信验证码，请妥善保管。 </p>
      </div>
    </van-form>
    <van-col span="24" style="position: relative;font-size:12px;background: #FFFFFF; padding: 10px 20px 0;margin-bottom: 10px;">
      <img src='@/assets/img/checkBox.png' class='checkedImg' v-if='ischeckedShow'/>
      <van-col span="2">
        <van-checkbox 
          v-model="checked" 
          shape="square" 
          icon-size="13px" 
          checked-color="#E50012" 
          style="display:inline-block;margin-right:5px;"
          @click="handleChecked"
        ></van-checkbox>
      </van-col>
      <van-col span="22" style="line-height:20px;">
        <span>我已阅读并同意</span> 
        <a href="javascript:;" @click="openPrivacy" style="color:#E50012">《隐私政策》</a>
        <a href="javascript:;" @click="openUserAgreement" style="color:#E50012">《用户服务协议》</a>
        <a href="javascript:;" @click="openAgreement" style="color:#E50012">《欧拉融资租赁账号绑定协议》</a>
      </van-col>
      <van-button round block type="danger" @click="onSubmit" style="margin-top: 60px;padding-right: 20px;">
      我是自然人
      </van-button>
      <van-button round block type="danger" plain @click="publicSubmit" style="margin-top: 20px;padding-right: 20px;">
      我是企业，下一步绑定企业
      </van-button>
    </van-col>
  </div>
</template>
<script>
  import moment from 'moment';
  import { Dialog,Toast } from 'vant';
  import Agreement from './../components/Agreement'
  import {doLogin,getOpenId,getMobileCode} from './../server'
  import { verifymsg, getpublicbindstatus } from '@/api/enterprise'
  import { setCookie, getCookie, domainUrl, hidewxOption } from './../lib/utils'
  export default {
  name: 'login',
  components: {
    Agreement
  },
  data() {
    return {
      cardType: '居民身份证',
      cardId: '',
      telNum: '',
      smsCode: '',
      checked: false,
      code: this.$route.query.code || '',
      // openId:localStorage.getItem("openId") ||this.$store.state.openId,
      openId:getCookie('openId'),
      accessToken: this.$store.state.accessToken,
      visible:false,
      time:'120000',
      timeSeconds:'',
      redirect: undefined,
      otherQuery: {},
      canclick: true,
      ischeckedShow: false
    };
  },
   watch: {
    $route: {
      handler: function(route) {
        const query = route.query;
        if (query) {
          this.redirect = query.redirect;
          this.otherQuery = this.getOtherQuery(query);
        }
      },
      immediate: true
    }
  },
  mounted(){
    console.log(domainUrl)
    sessionStorage.setItem('key', 1);
    window.addEventListener("popstate", function(e) {  //回调函数中实现需要的功能
      if(sessionStorage.getItem('key') == 1) {
        document.addEventListener('WeixinJSBridgeReady', function(){ WeixinJSBridge.call('closeWindow'); }, false); 
        WeixinJSBridge.call('closeWindow');
      }
    }, false);
  },
  created(){
    //隐藏分享
    hidewxOption()
    Toast.clear();
    // alert(getCookie('openId'))
    this.$store.commit('alertModal', { modalBtnCancel:false,fn:null});
    //已授权,已获得openId
      if (this.openId !=='' && this.openId !==null && this.openId !== 'None') {
        getOpenId({openid:this.openId}).then(res=>{
          if(res.wx_code=='0'){
            this.$store.commit('bindStatus', res.bind_status);
            localStorage.setItem("bindStatus", res.bind_status);
            localStorage.setItem("accessToken", res.access_token);
            this.$store.commit('accessToken', res.access_token);
            // localStorage.setItem("openId", res.openid);
            this.$store.commit('openId', res.openid);
            // localStorage.setItem("openId", res.openid);
            setCookie('openId',res.openid);
            this.$store.commit('cardId', res.card_id);
            localStorage.setItem("cardId", res.card_id);
          }else{
            Toast(res.msg);
          }
        })
      } else{
      //没有授权,没有获取code
        if(!this.code) {
          console.log(this.redirect)
        //测试环境http://wechattest.1jianqidong.com //生产环境 http://wechat.orafl.com
        let uiCode = encodeURIComponent(domainUrl+'/login?redirect='+this.redirect);
        window.location.href = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxe40be82e1238dfe7&redirect_uri='+uiCode+'&response_type=code&scope=snsapi_userinfo&state=STATE&connect_redirect=1#wechat_redirect';         
        }else{//获取code
          getOpenId({percode:this.code}).then(res=>{
            if(res.wx_code=='0'){
              this.$store.commit('bindStatus', res.bind_status);
              localStorage.setItem("bindStatus", res.bind_status);
              // localStorage.setItem("token", res.token);
              // setCookie("token",res.token, 365);
              localStorage.setItem("accessToken", res.access_token);
              // setCookie("accessToken",res.access_token, 365);
              this.$store.commit('accessToken', res.access_token);
              // localStorage.setItem("openId", res.openid);
              setCookie('openId',res.openid);
              // setCookie("openId",res.openid, 365);
              this.$store.commit('openId', res.openid);
              this.$store.commit('cardId', res.card_id);
              localStorage.setItem("cardId", res.card_id);
            }else{
              Toast(res.msg);
            }
          })
      }
    } 
  },
  methods: {
    getOtherQuery(query) {
      return Object.keys(query).reduce((acc, cur) => {
        if (cur !== 'redirect') {
          acc[cur] = query[cur];
        }
        return acc;
      }, {});
    },
    validator(val,rule) {
      if(val.length!=11){
        return false;
      }
    },
    formatter(value) {
      // 只能输入数字/\D/g,''
      return value.replace(/\D/g, '');
    },
    validatorCard(s) {
          // 检查长度是否合法
      switch (s.length) {
        // case 15:
        case 18: {
          break;
        }
        default: {
          return false;
        }
      }
      // 检查校验位是否合法
      // if (s.length == 18) {
      //   var testNumber = (parseInt(s.charAt(0)) + parseInt(s.charAt(10))) * 7 +
      //     (parseInt(s.charAt(1)) + parseInt(s.charAt(11))) * 9 +
      //     (parseInt(s.charAt(2)) + parseInt(s.charAt(12))) * 10 +
      //     (parseInt(s.charAt(3)) + parseInt(s.charAt(13))) * 5 +
      //     (parseInt(s.charAt(4)) + parseInt(s.charAt(14))) * 8 +
      //     (parseInt(s.charAt(5)) + parseInt(s.charAt(15))) * 4 +
      //     (parseInt(s.charAt(6)) + parseInt(s.charAt(16))) * 2 +
      //     parseInt(s.charAt(7)) * 1 +
      //     parseInt(s.charAt(8)) * 6 +
      //     parseInt(s.charAt(9)) * 3;
      //   if (s.charAt(17) != "10x98765432".charAt(testNumber % 11) && s.charAt(17) != "10X98765432".charAt(testNumber % 11)) {
      //     return false;
      //   }
      // }
      return true;
    },
    openAgreement() {
      sessionStorage.removeItem('key');
      this.$router.push({ path: '/bindAgreement'})
      // this.$store.commit('updatedOpenAgree', true)
    },
    openPrivacy(){
      sessionStorage.removeItem('key');
      this.$router.push({ path: '/privacy'})    
    },
    openUserAgreement(){
      sessionStorage.removeItem('key');
      this.$router.push({ path: '/uerAgreement'})
    },
    sendMessage(second) {
      if(second >= 0) {
        this.visible = true;
        this.timeSeconds = second + "s"
        second--;
        setTimeout(() => {
          this.sendMessage(second);
        }, 1000);
      } else {
        this.visible = false;
      }
    },
    //获取验证码
    getSmsCode(){
      if(this.canclick){
        // this.$refs.countDown.start();
        this.$refs.telNum.validate();
        this.$refs.cardId.validate();
        const params = {
          card_id:this.cardId.toUpperCase(),
          mobile: this.telNum,
          openid: this.$store.state.openId
        }
        if(this.telNum !=='' && this.telNum.length=='11' && this.cardId !==''){
          this.canclick = false
          getMobileCode(params).then(res =>{
            this.canclick = true
            //"cus_status:0-有合同并发送验证码,1-有合同发送验证码失败,2-有合同但手机号不一致,3-没有起租合同"
            if(res.cus_status=='0'){
              this.sendMessage(120);
            }else{
              Toast.fail({
                message: res.msg,
                duration:'3000'
              });
            }
            // else if(res.cus_status=='1'){
            //   Toast.fail({
            //     message: '发送验证码失败',
            //     duration:'3000'
            //     // position: 'top',
            //     // duration:''
            //   });
            // }else if(res.cus_status=='2'){
            //     Toast.fail({
            //     message: '此手机号与预留手机号不一致，请核对',
            //     duration:'3000'
            //   });
            // }else if(res.cus_status=='3'){
            //     Toast.fail({
            //     message: res.msg,
            //     duration:'3000'
            //   });
            // }else if(res.cus_status=='4'){
            //     Toast.fail({
            //     message: res.msg,
            //     duration:'3000'
            //   });
            // }
          })
        }  
      }
    },
    finish() {
      this.visible = false;
      // Toast('倒计时结束');
      this.$refs.countDown.reset();
    },
    handleChecked(){
      if(this.checked){
        this.ischeckedShow = false
      }
    },
    onSubmit() {
      this.$refs.ruleForm.validate().then(() => {
        if(!this.checked){
          this.ischeckedShow = true
          return
        }
        const params = {
          openid:this.$store.state.openId,
          access_token:this.$store.state.accessToken,
          card_type:'身份证',
          card_id:this.cardId.toUpperCase(),
          mobile:this.telNum,
          inputcode:this.smsCode
        }
        //"binding_status:0--绑定成功（未绑定过,已绑定且数据一致,已绑定但数据需更新）;3--验证码不正确"
        doLogin(params).then(res =>{
          if(res.binding_status =='1'){
            this.$store.commit('bindStatus', res.binding_status);
            localStorage.setItem("bindStatus", res.binding_status);
            this.$store.commit('cardId', this.cardId.toUpperCase());
            localStorage.setItem("cardId", this.cardId.toUpperCase());
            Toast({
              message: '绑定成功！',
              icon: require('../assets/img/bindSuccess.png'),
              duration:'3000'
            });
            this.$router.push({ path: this.redirect || '/', query: this.otherQuery });
          }else if(res.binding_status =='3'){
            Toast({
              message: res.msg,
              icon: require('../assets/img/bindError.png'),
              duration:'3000'
            });
          }else{
            Toast({
              message: res.msg,
              icon: require('../assets/img/bindError.png'),
              duration:'3000'
            });
          }
        })

      })
      
    },
    publicSubmit(){
      this.$refs.ruleForm.validate().then(() => {
        if(!this.checked){
          this.ischeckedShow = true
          return
        }
        verifymsg({
          mobile: this.telNum,
          vfcode: this.smsCode,
          openid: this.$store.state.openId,
          card_id: this.cardId.toUpperCase()
        }).then(res =>{
          if(res.vf_status == '1'){
            this.publicbindStatus()
            
          }else{
            Toast.fail({
              message: res.msg,
              duration:'3000'
            });
          }
        })
      })
    },
    publicbindStatus(){
      getpublicbindstatus({
        openid: this.$store.state.openId
      }).then(res =>{
        if(res.bind_status == '1' && res.cus_type == '01'){
          sessionStorage.removeItem('key');
          this.$router.push({ path: '/enterprise/enterprisePersonalCenter'})
        }else{
          sessionStorage.removeItem('key');
          this.$router.push({ path: '/enterprise/enterpriseBind',query: {telNum: this.telNum, cardId: this.cardId.toUpperCase()}})
        }
      })
    }
  }
};
</script> 
<style scoped>
.wrap{
  height: 100vh !important;
}
.loginFlex{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.van-button--default{
  border: 0px;
}
.tips{
  color:#808080;
  font-size:13px;
  margin-top: 20px;
}
.tips p{
    margin: 6px 15px;
}
.van-cell{
  font-size: 15px !important;
  padding: 15px;
}
.block {
  display: inline-block;
  padding: 5px 28px;
  font-size: 16px;
  width: 80px;
  color: #ee0a24;
  text-align: center;
  border: 1px solid #ee0a24;
  border-radius: 3px;
  /* background-color: #ee0a24; */
}
</style>
<style>
.wrap .van-field__control{
  color: #666666 !important;
}
/* #app{
  background: #fff !important;
} */
.checkedImg{
  width: 73px;
  height: 42px;
  position: absolute;
  left: 5px;
  top: -30px;
}
</style>